<template>
  <input
    v-model="model"
    :class="inputClasses"
    :type="type"
  >
</template>

<script setup lang="ts">
import type { InputTypeHTMLAttribute } from 'vue'
import type { FormFormatterFunc, FormIcon, FormVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  type?: InputTypeHTMLAttribute
  small?: boolean
  state?: boolean
  variant?: FormVariant
  icon?: FormIcon
  formatter?: FormFormatterFunc
  noFocusOutline?: boolean
}>(), {
  type: 'text',
  small: false,
  state: true,
  variant: 'light',
  formatter: (val: string) => val,
  noFocusOutline: false,
})

const inputClasses = computed(() => {
  return [
    'zform-control',
    `zform-control-${props.variant}`,
    props.icon ? `zform-control-icon-${props.icon}` : '',
    {
      'zform-control-small': props.small,
      'zform-control-invalid': props.state === false,
      'zform-control-no-focus-outline': props.noFocusOutline,
    },
  ]
})

const model = defineModel<string>({
  set(value) {
    return props.formatter(value)
  },
})
</script>
